
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { PageState } from '@/models/PageState';
import { PaginatedTableMixin, VuetifyMixin, StringsMixin, CoachRoutingMixin, BAIconsMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import { AthleteProfileModel } from '@/models'
import { TeamModel } from '@/models/team';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { TargetedSharingModel } from '@/models';
import { targetedSharingStore } from '@/store';
import { RepositoryQuery, QueryOptions } from "@/../types/interfaces";
import { TargetedSharingData } from '@/models'
import { RecruitingResponse } from '@/../types/enums/recruiting-response';

@Component({
	components: {
		Page,
	}
})
export default class CoachSharesAdminDashboard extends Mixins(PaginatedTableMixin, VuetifyMixin, StringsMixin, CoachRoutingMixin, BAIconsMixin){
	RecruitingResponse = RecruitingResponse;
	TargetedSharingData = TargetedSharingData;
	pageState: PageState = new PageState('Initial');
	search: string = '';
	localForagePersistFields: Array<string | [string, any]> = [
		['search', ''],
		['tableOptions.page', 1],
		['tableOptions.itemsPerPage', 10],
		['viewResponses', []]
	];

 	mounted() {
		this.tableOptions.sortBy = ['date'];
		this.tableOptions.sortDesc = [true];
	}

	// Selected athlete to filter results for
	athlete: AthleteProfileModel | null = null;
	updateAthlete(athlete: AthleteProfileModel): void {
		this.athlete = athlete ?? null;
		this.updateSearch();
	}

  	// Selected team to filter results for
	team: TeamModel | null = null;
	updateTeam(team: TeamModel): void {
		this.team = team ?? null;
		this.updateSearch();
	}

	// Selected organization to filter results for
	organization: OrganizationModel | null = null;
	updateOrganization(organization: OrganizationModel): void {
		this.organization = organization ?? null;
		this.updateSearch();
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Date', value: 'date', sortable: false },
			{ text: 'Athlete', value: 'athlete', sortable: false },
			{ text: 'athleteId', value: 'parentId', sortable: false },
			{ text: 'Coach', value: 'coach', sortable: false },
			{ text: 'coachId', value: 'recipientData.coachProfileId', sortable: false },
			{ text: 'response', value: 'recipientData.response', sortable: true },
		];
		if( this.IsLargeScreen ) {
			headers.push({ text: '', value: 'actions', sortable: false });
		}
		return headers;
	}
	async loadTable(): Promise<void>{
		this.pageState = new PageState('Loading');
		const query: RepositoryQuery<TargetedSharingModel> = {
			search: this.athlete?.id,
			fields: ['parentId'],
		};
		var responses: Array<RecruitingResponse> = [];
		if( this.isInView(RecruitingResponse.Yes) ) responses.push(RecruitingResponse.Yes);
		if( this.isInView(RecruitingResponse.Maybe) ) responses.push(RecruitingResponse.Maybe);
		if( this.isInView(RecruitingResponse.No) ) responses.push(RecruitingResponse.No);
		if( this.isInView(RecruitingResponse.Seen) ) responses.push(RecruitingResponse.Seen);
		if( responses.length > 0 ) query.$match = {...query.$match, ...{ 'recipientData.response': { $any: responses } } };
		if( this.isInView(RecruitingResponse.unseen) ) query.$match = {...query.$match, ...{ 'seen': false } };

		const options: QueryOptions = this.TableQueryOptions;
		await targetedSharingStore.queryTargetedShares({query, options});
		this.dataItemsCount = targetedSharingStore.totalTargetedShares;
		this.pageState = new PageState('Ready');
	}
	get PageLoading(): boolean{
		return this.TableLoading;
	}
	get TableLoading(): boolean {
		return targetedSharingStore.queryTargetedSharesLoading;
	}
	get CoachShares(): TargetedSharingModel[] {
		return targetedSharingStore.targetedShares;
	}

	viewResponses: Array<RecruitingResponse> = [];
	async onToggleView(response: RecruitingResponse) {
		if( this.viewResponses.includes(RecruitingResponse.unseen) ) this.viewResponses = [];

		const index = this.viewResponses.indexOf(response)
		if( index < 0 ) {
			this.viewResponses.push(response);
		} else {
			this.viewResponses.splice(index, 1);
		}
		this.updateFilter();
	}
	async onViewUnseen() {
		this.viewResponses = [RecruitingResponse.unseen];
		this.updateFilter();
	}
	async onViewAll() {
		this.onResetFilters();
	}
	async onResetFilters() {
		this.athlete = undefined;
		this.viewResponses = [];
		this.updateFilter();
	}
	isInView(response: RecruitingResponse) {
		return this.viewResponses.includes(response);
	}
	ToolTip(response: RecruitingResponse) {
		if( this.isInView(response) ) return TargetedSharingData[response].tooltip.filter.on;
		return TargetedSharingData[response].tooltip.filter.off;
	}
	ButtonColor(response: RecruitingResponse) {
		if( this.isInView(response) ) return TargetedSharingData[response].colors.base;
		return TargetedSharingData[response].colors.light;
	}
	IconColor(response: RecruitingResponse) {
		if( this.isInView(response) ) return TargetedSharingData[response].colors.icon;
		return TargetedSharingData[response].colors.lightIcon;
	}
	TextColor(response: RecruitingResponse) {
		if( this.isInView(response) ) return TargetedSharingData[response].colors.text;
		return TargetedSharingData[response].colors.lightText;
	}
}
