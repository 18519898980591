
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { PageState } from '@/models/PageState';
import { PaginatedTableMixin, VuetifyMixin, CoachRoutingMixin, StringsMixin, BAIconsMixin } from '@/mixins';
import { adminStore } from '@/store';
import { DataTableHeader } from 'vuetify';

import VerifiedAssessmentImportV4 from '@/components/admin/VerifiedAssessmentImportV4.vue';
import { AthleteAssessmentDataModel, AthleteAssessmentDataModelEx, AthleteProfileModel } from '@/models';
import { athleteAssessmentDataApi } from '@/api/AthleteAssessmentDataApi';
import { AssessmentEntry } from '@/../types/interfaces/PyhsicalAssessmentDataFormValue';
import VerifiedAssessmentImportStatus from '@/components/admin/VerifiedAssessmentImportStatus.vue';
import {TeamModel} from '@/models/team';
import {OrganizationModel} from '@/models/organization/OrganizationModel';
import { notificationStore } from '@/store/index';
import {AgeGroupValues} from '@best-athletes/ba-types';
import { AthletesCacheMixin } from '@/mixins/AthletesCacheMixin';

@Component({
	components: {
		Page,
    	VerifiedAssessmentImportStatus,
		VerifiedAssessmentImportV4,
	}
})
export default class VerifiedAssessmentAdminDashboard extends Mixins(PaginatedTableMixin, VuetifyMixin, CoachRoutingMixin, StringsMixin, BAIconsMixin, AthletesCacheMixin){
	pageState: PageState = new PageState('Initial');
	search: string = '';
	jobIds: number[] = [];
	uploadStatus: number = 0;

 	mounted() {
		this.tableOptions.itemsPerPage = 10;
		this.tableOptions.sortBy = ['assessmentDate'];
		this.tableOptions.sortDesc = [true];
		this.search = '';
	}

	// Selected athlete to filter results for
	athlete: AthleteProfileModel | null = null;
	updateAthlete(athlete: AthleteProfileModel): void{
		this.athlete = athlete ?? null;
		this.updateSearch();
	}

  	// Selected team to filter results for
	team: TeamModel | null = null;
	updateTeam(team: TeamModel): void {
		this.team = team ?? null;
		this.updateSearch();
	}

	// Selected organization to filter results for
	organization: OrganizationModel | null = null;
	updateOrganization(organization: OrganizationModel): void {
		this.organization = organization ?? null;
		this.updateSearch();
	}

	// Selected age group to filter results for
	ageGroup: number | null = null;
	updateAgeGroup(ageGroup: number): void {
		this.ageGroup = ageGroup ?? null;
		this.updateSearch();
	}
	get AgeGroups(): Record<string, string>[] {
		return AgeGroupValues.map(ag => ({
			text: String(ag),
			value: String(ag),
		}))
	}

	updateUpload() {
		this.uploadStatus = 0;
		this.updateTable();
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Date', value: 'assessmentDate', sortable: false },
			{ text: 'Athlete', value: 'lastName', sortable: false },
			{ text: 'Gnd', value: 'gender', sortable: false },
			{ text: 'Age', value: 'playingLevel', sortable: false },
			{ text: 'Height', value: 'standingHeight', sortable: false },
			{ text: 'Weight', value: 'mass', sortable: false },
			{ text: 'Acc', value: 'acceleration', sortable: false },
			{ text: 'Spd', value: 'speed', sortable: false },
			{ text: 'Agl', value: 'agility', sortable: false },
			{ text: 'Pow', value: 'power', sortable: false },
			{ text: 'Rec', value: 'recovery', sortable: false },
			{ text: 'athleteId', value: 'athleteId', sortable: false },
		];
		if( this.IsLargeScreen ) {
			headers.push({ text: '', value: 'actions', sortable: false });
		}
		return headers;
	}

	verifiedAssessments: Array<AthleteAssessmentDataModelEx>;
	async loadTable(): Promise<void>{
		this.pageState = new PageState('Loading');
		try {
			var query = this.TableQuery<AthleteAssessmentDataModel>(this.search, ['athleteId', 'assessmentDate', 'tags']);
			if( !!this.athlete ) { query.$match = {...query.$match, ...{ athleteId: this.athlete.id, } } }
    		if( !!this.team ) { query.$match = {...query.$match, ...{ teamIds: this.team.id, } } }
			if (this.organization !== null) { query.$match = { ...query.$match, ...{ 'teams.organizations.orgId': this.organization.id, } } }
    		if (this.ageGroup !== null) {query.$match = { ...query.$match, ...{ playingLevel: this.ageGroup, } } }

			await adminStore.loadVerifiedAssessmentList({ query, options: this.TableQueryOptions });

			this.verifiedAssessments = await Promise.all( adminStore.verifiedAssessmentList.map( async(assessment: AthleteAssessmentDataModel) => {
				const verified: AthleteAssessmentDataModelEx = new AthleteAssessmentDataModelEx().load(assessment);
				verified.athlete = await this.AthleteRetrieve(assessment.athleteId);
				return verified;
			}))

		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error loading athletes: ${e}`});
		} finally {
			this.pageState = new PageState('Ready');
		}
	}
	get PageLoading(): boolean{
		return this.TableLoading || !adminStore.verifiedAssessmentsInitialized || adminStore.organizationsLoading;
	}
	get TableLoading(): boolean {
		return adminStore.verifiedAssessmentsLoading
	}
	get TotalItems(): number{
		return adminStore.totalVerifiedAssessments;
	}
	get Assessments(): AthleteAssessmentDataModel[]{
		return adminStore.verifiedAssessmentList;
	}

  	async bulkImportAssessmentsV4({assessments,notify,fixDuplicates}: { assessments: AssessmentEntry[], notify: boolean, fixDuplicates: boolean }): Promise<void> {
		this.uploadStatus = 1;
		const {jobIds} = await athleteAssessmentDataApi.bulkInsertV4({assessments,notify,fixDuplicates});
    	this.jobIds = jobIds;
	}

	viewData = "view-measured";
	get isViewMeasured(): boolean {
		return this.viewData == "view-measured";
	}
	get isViewComputed(): boolean {
		return this.viewData == "view-computed";
	}

    async onDelete(assessment: AthleteAssessmentDataModel) {
        if( !confirm(`Delete assessment? This action cannot be undone`) ) return;

        await adminStore.deleteVerifiedAssessment(assessment);
        this.updateTable();
    }
}
